// Angular Modules
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { Attachment, IUploadService, QueryParameters, QueryResults } from '@models/index';
import { Firmware } from '@configuration/firmwares/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { SearchStrategy } from '@enums/search-strategy.enum';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'firmware'


@Injectable({
  providedIn: 'root'
})
export class FirmwareService implements IUploadService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  findLazy(queryParameters: QueryParameters): Observable<QueryResults<Firmware>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ =  this.httpQueryUtils.executeQuery<Firmware>( url, queryParameters);

    return queryResults$;
  }

  find(queryParameters: QueryParameters, searchStrategy?: SearchStrategy): Observable<QueryResults<Firmware>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ =  this.httpQueryUtils.executeQuery<Firmware>( url, queryParameters);

    if (searchStrategy === SearchStrategy.EAGER) {
      queryResults$ = queryResults$.pipe(
        switchMap((queryResults: QueryResults<Firmware>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          return this.fillRelatedAttributes(queryResults.items)
            .pipe(
              map(() => queryResults))
        })
      )
    }

    return queryResults$;
  }

  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Firmware): Observable<Firmware> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Firmware>(url, entity);
  }


  update(entity: Firmware): Observable<Firmware> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Firmware>(url, entity);
  }


  findById(id: number, strategy?: SearchStrategy): Observable<Firmware> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<Firmware>(url, {})
    .pipe(
      map(result => result.items[0])
    );

    if (strategy === SearchStrategy.EAGER) {

      queryResults$ = queryResults$
        .pipe(
          switchMap((itemParent: Firmware) => {

            return this.fillRelatedAttributes([itemParent])
              .pipe(
                map((items) => items[0]))
          })
        )
    }

    return queryResults$;
  }

  private fillRelatedAttributes(items: Firmware[]) {

    const requestDetail_1: Observable<Attachment>[] = this.getDetailObservablesAttachment(items);

    // la caña de España!
    return forkJoin([
      ...requestDetail_1
    ])
      .pipe(
        map((arrayWithDetailData: any) => {

          const arrLength = items.length;

          const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);

          items
            .map((item, i) => {

              console.log(resultDetail_1[i]);


              item.attachment = resultDetail_1[i];

              return item;
            });

          return items;
        })
      );
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesAttachment(items: Firmware[]): Observable<Attachment>[] {

    return items.map((item: Firmware) => {

      if (item.id && item.id > 0) {
        return this.findDocument(item.id)
      } else {
        return of();
      }

    });

  }

  findDocument(idFirmware: number): Observable<Attachment> {

    const url = `${BACKEND_API}/${ENTITY}/${idFirmware}/document`;

    return this.http.get<Attachment>(url);
  }

  dowloadDocument(idFirmware: number, uuidDocument: string): Observable<Blob> {

    const url = `${BACKEND_API}/${ENTITY}/${idFirmware}/document/${uuidDocument}/`;

    return this.http.get(url, { responseType: 'blob' });
  }

  deleteDocument(idFirmware: number, idAttachment: number): Observable<void> {

    const url = `${BACKEND_API}/${ENTITY}/${idFirmware}/document/${idAttachment}`;

    return this.http.delete<void>(url);
  }

  uploadDocument(idFirmware: number, file: File): Observable<HttpEvent<Attachment>> {

    const url = `${BACKEND_API}/${ENTITY}/${idFirmware}/document/`;

    const formData = new FormData();
    formData.append('multipartFile', file, file.name);

    const requestOptions = {
      // headers: headers,
      observe: 'events' as const,
      reportProgress: true,
      withCredentials: true,
    };

    return this.http.post<Attachment>(url, formData, requestOptions);
  }

}
